export enum Colors {
  'ACCENT1000' = '#1D7726',
  'ACCENT500' = '#31C940',
  'ACCENT100' = '#84E18D',
  'PRIMARY1000' = '#121212',
  'PRIMARY500' = '#F4F4F4',
  'PRIMARY100' = '#FFFFFF',
  'TEXT1000b' = '#171717',
  'TEXT1000w' = '#9E9E9E',
  'NEUTRAL500' = '#868686'
}

import { css } from '@emotion/css';
import { Colors } from './colors';
import { DeviceSizes } from '../utils/device-sizes';

export const inactiveNavigationElementFont = css`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;

  /* identical to box height, or 14px */
  letter-spacing: 0.06em;
  color: ${Colors.TEXT1000b};
`;

export const activeNavigationElementFont = css`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;

  /* identical to box height, or 14px */
  letter-spacing: 0.06em;
  color: ${Colors.ACCENT1000};
  border-bottom: 2px solid ${Colors.ACCENT1000};
  padding-bottom: 4px;
`;

export const UIElementsFont = css`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 116%;

  /* or 16px */
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${Colors.PRIMARY100};
`;

export const desktopHeadingOverline = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 140%;

  /* identical to box height, or 28px */
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${Colors.TEXT1000b};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    font-size: 20px;
  }

  @media only screen and (max-width: ${DeviceSizes.MOBILE}px) {
    font-size: 14px;
  }
`;

export const headingH1 = css`
  font-family: 'Libre Baskerville', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 110%;

  /* or 79px */
  letter-spacing: -0.03em;
  color: ${Colors.TEXT1000b};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    font-size: 44px;
  }

  @media only screen and (max-width: ${DeviceSizes.MOBILE}px) {
    font-size: 36px;
  }
`;

export const headingH2 = css`
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;

  /* or 52px */
  letter-spacing: -0.03em;
  color: ${Colors.ACCENT1000};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    font-size: 28px;
  }

  @media only screen and (max-width: ${DeviceSizes.MOBILE}px) {
    font-size: 24px;
  }
`;

export const headingH3 = css`
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 130%;

  letter-spacing: -0.03em;
  color: ${Colors.ACCENT1000};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    font-size: 24px;
  }
`;

export const paragraph = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: ${Colors.TEXT1000b};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    font-size: 16px;
  }
`;

export const serifParagraph = css`
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;

  /* or 26px */
  text-align: center;
`;

export const footerText = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 120%;

  /* identical to box height, or 17px */
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${Colors.NEUTRAL500};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    font-size: 10px;
  }
`;

import { css } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import { desktopHeadingOverline, headingH3 } from '../../../shared/styles/fonts';
import { Container } from '../../../shared/components/Container';

export const FirstQuoteSection = () => {
  return (
    <section className={containerStyle}>
      <Container>
        <h4 className={subHeadingStyle}>Sveti Antun</h4>
        <h3 className={headingStyle}>
          “Mjesto za starije osobe koje žele svoj život provesti u miru i zadovoljstu.”
        </h3>
      </Container>
    </section>
  );
};

const containerStyle = css`
  min-height: 16.5rem;
  background-color: ${Colors.PRIMARY500};
  padding: 4rem 0;
`;

const headingStyle = css`
  text-align: center;
  ${headingH3}
`;

const subHeadingStyle = css`
  margin-bottom: 2rem;
  ${desktopHeadingOverline}
`;

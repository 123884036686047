import { css } from '@emotion/css';
import {
  desktopHeadingOverline,
  headingH2,
  paragraph,
  UIElementsFont
} from '../../../shared/styles/fonts';
import { Container } from '../../../shared/components/Container';
import { Colors } from '../../../shared/styles/colors';
import React from 'react';
import { DeviceSizes } from '../../../shared/utils/device-sizes';

export const PersonnelSection = () => {
  return (
    <section className={containerStyle}>
      <Container>
        <div className={columnsStyle}>
          <img src={'/assets/personel.png'} className={imageStyle} />

          <div>
            <h2 className={headingStyle}>Zadovoljstvo naših korisnika nam je najvažnije</h2>
            <h4 className={subHeadingStyle}>
              U tu svrhu zapošljavamo nadasve ljubazno, stručno i kvalificirano osoblje:
            </h4>
            <ul className={listStyle}>
              <li className={listItemStyle}>medicinske sestre/medicinske tehničare</li>
              <li className={listItemStyle}>njegovatelje</li>
              <li className={listItemStyle}>fizioterapeuta</li>
              <li className={listItemStyle}>socijalnog radnika</li>
              <li className={listItemStyle}>
                pomoćno osoblje (koje je uvijek spremno pomoći i pružiti vrhunsku uslugu)
              </li>
            </ul>
            <a href={'#kontakt'} className={contactUsContainerStyle}>
              <h3 className={contactUsTextStyle}>Kontaktirajte nas</h3>
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

const containerStyle = css`
  min-height: 47.75rem;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const headingStyle = css`
  text-align: left;
  ${headingH2};
  color: ${Colors.TEXT1000b};
  margin-bottom: 3rem;
`;

const subHeadingStyle = css`
  margin-bottom: 1rem;
  ${desktopHeadingOverline};
  text-align: left;
  color: ${Colors.ACCENT1000};
`;

const columnsStyle = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    grid-template-columns: auto;
  }
`;

const imageStyle = css`
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    margin-bottom: 4rem;
  }
`;

const listStyle = css`
  margin-left: 2rem;
`;

const listItemStyle = css`
  display: list-item;
  list-style-type: disc;
  ${paragraph};
`;

const contactUsContainerStyle = css`
  z-index: 2;
  display: inline-block;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  background-color: ${Colors.ACCENT1000};
  height: 3.5rem;
  border-radius: 3.5rem;
  margin-top: 2.5rem;
`;

const contactUsTextStyle = css`
  z-index: 2;
  ${UIElementsFont}
`;

import { css } from '@emotion/css';
import { desktopHeadingOverline, headingH3 } from '../../../shared/styles/fonts';
import { Container } from '../../../shared/components/Container';

export const SecondQuoteSection = () => {
  return (
    <section className={containerStyle}>
      <Container>
        <h3 className={headingStyle}>
          “Dom sveti Antun posebnu pažnju posvećuje pacijentima u terminalnoj fazi života.”
        </h3>
      </Container>
    </section>
  );
};

const containerStyle = css`
  min-height: 16.5rem;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const headingStyle = css`
  text-align: center;
  ${headingH3}
`;

const subHeadingStyle = css`
  margin-bottom: 2rem;
  ${desktopHeadingOverline}
`;

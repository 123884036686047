import React from 'react';
import { css } from '@emotion/css';
import { DeviceSizes } from '../utils/device-sizes';

export const Container: React.FC = (props) => {
  return <div className={containerStyle}>{props.children}</div>;
};

const containerStyle = css`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: ${DeviceSizes.WIDESCREEN}px;
  padding-right: clamp(2rem, 5.625rem, 5.625rem);
  padding-left: clamp(2rem, 5.625rem, 5.625rem);

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

import { css, cx } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import {
  desktopHeadingOverline,
  headingH3,
  paragraph,
  serifParagraph
} from '../../../shared/styles/fonts';
import { Container } from '../../../shared/components/Container';
import React from 'react';
import { DeviceSizes } from '../../../shared/utils/device-sizes';

export const WhySection = () => {
  return (
    <section id={'usluge'} className={containerStyle}>
      <Container>
        <h3 className={headingStyle}>Zašto odabrati dom Sveti Antun?</h3>
        <h4 className={subHeadingStyle}>Pojedinosti koje bi istaknuli</h4>
        <div className={columnContainerStyle}>
          <div className={columnStyle}>
            <img src={'/assets/hands.png'} className={columnImageStyle} />
            <p className={columnTextStyle}>Dugogodišnje iskustvo</p>
          </div>
          <div className={columnStyle}>
            <img src={'/assets/friends.png'} className={columnImageStyle} />
            <p className={columnTextStyle}>Fizikalna terapija</p>
          </div>
          <div className={columnStyle}>
            <img src={'/assets/barbers.png'} className={columnImageStyle} />
            <p className={columnTextStyle}>Usluge frizera i pedikera</p>
          </div>
          <div className={columnStyle}>
            <img src={'/assets/plitvice.png'} className={columnImageStyle} />
            <p className={columnTextStyle}>Izleti unutar Hrvatske</p>
          </div>
        </div>
      </Container>
    </section>
  );
};

const containerStyle = css`
  min-height: 16.5rem;
  background-color: ${Colors.PRIMARY500};
  padding: 5.75rem 0;
`;

const headingStyle = css`
  text-align: center;
  margin-bottom: 1rem;
  ${headingH3};
`;

const subHeadingStyle = css`
  text-align: center;
  margin-bottom: 4.5rem;
  ${desktopHeadingOverline};
`;

const columnContainerStyle = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 2.25rem;
  min-height: 20rem;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    grid-template-columns: auto;
  }
`;

const columnStyle = css`
  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    margin-bottom: 2rem;
  }
`;
const columnImageStyle = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const columnTextStyle = css`
  text-align: center;
  ${serifParagraph};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    text-align: center;
  }
`;

import { NavigationBar } from './shared/components/NavigationBar';
import { HomePage } from './pages/home/HomePage';
import { Footer } from './shared/components/Footer';

export default function App() {
  return (
    <main>
      <NavigationBar />
      <HomePage />
      <Footer />
    </main>
  );
}

import { css } from '@emotion/css';
import { headingH3 } from '../../../shared/styles/fonts';
import { Container } from '../../../shared/components/Container';
import { Colors } from '../../../shared/styles/colors';
import { DeviceSizes } from '../../../shared/utils/device-sizes';

const images = [
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-1.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-2.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-3.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-4.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-5.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-6.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-7.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-8.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-9.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-10.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-11.png'
  },
  {
    thumbnail: 'assets/gallery/thumbnails/gallery-12.png'
  }
];

export const GallerySection = () => {
  return (
    <section id={'galerija'} className={containerStyle}>
      <Container>
        <h3 className={headingStyle}>Galerija</h3>
        <div className={imageGridStyle}>
          {images.map((image) => {
            return <img key={image.thumbnail} src={image.thumbnail} />;
          })}
        </div>
      </Container>
    </section>
  );
};

const containerStyle = css`
  background-color: ${Colors.PRIMARY500};
  min-height: 71.5rem;
  padding: 4rem 0;
`;

const headingStyle = css`
  text-align: center;
  ${headingH3};
  margin-bottom: 3rem;
`;

const imageGridStyle = css`
  line-height: 0;
  -webkit-column-count: 4;
  -webkit-column-gap: 0px;
  -moz-column-count: 4;
  -moz-column-gap: 0px;
  column-count: 4;
  column-gap: 0px;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2;
  }

  @media only screen and (max-width: ${DeviceSizes.MOBILE}px) {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
  }

  img {
    width: 100% !important;
    height: auto !important;
  }
`;

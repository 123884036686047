import { css } from '@emotion/css';
import { headingH1, UIElementsFont } from '../../../shared/styles/fonts';
import { Colors } from '../../../shared/styles/colors';
import { useMediaQuery } from 'react-responsive';
import { DeviceSizes } from '../../../shared/utils/device-sizes';
import { Container } from '../../../shared/components/Container';

export const LandingSection = () => {
  const isTablet = useMediaQuery({ maxWidth: DeviceSizes.TABLET });
  const isMobile = useMediaQuery({ maxWidth: DeviceSizes.MOBILE });

  return (
    <section id={'o-domu'}>
      <div className={landingImageStyle(isMobile)} />
      <div className={gradientImageOverlayStyle} />
      <Container>
        <div className={mainTitleContainerStyle}>
          <div className={mainTitleContentContainerStyle}>
            <div className={mainTitleDashStyle(isTablet, isMobile)} />
            <div className={mainTitleTextColumnStyle}>
              <h1 className={mainTitleStyle(isTablet, isMobile)}>
                Dom za starije i nemoćne <span className={mainTitleAccentStyle}>Sveti Antun</span>
              </h1>
              <a href={'#kontakt'} className={contactUsContainerStyle(isMobile)}>
                <h3 className={contactUsTextStyle}>Kontaktirajte nas</h3>
                <div className={phoneIconBackgroundStyle(isMobile)}>
                  <img src={'/assets/phone.png'} className={phoneImageStyle} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const landingImageStyle = (isMobile: boolean) => css`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('/assets/landing.png');
  background-position: ${isMobile ? '65%' : 'center'};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 840px;
`;

const gradientImageOverlayStyle = css`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 840px;
  background: linear-gradient(90deg, rgba(244, 244, 244, 0.8) 0%, rgba(244, 244, 244, 0) 100%);
`;

const mainTitleContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 840px;
`;

const mainTitleContentContainerStyle = css`
  display: flex;
`;

const mainTitleStyle = (isTablet: boolean, isMobile: boolean) => css`
  ${headingH1};
  z-index: 2;
  width: ${isMobile ? 272 : isTablet ? 332 : 552}px;
`;

const mainTitleTextColumnStyle = css`
  display: flex;
  flex-direction: column;
`;

const mainTitleAccentStyle = css`
  color: ${Colors.ACCENT1000};
`;

const mainTitleDashStyle = (isTablet: boolean, isMobile: boolean) => css`
  z-index: 2;
  background-color: ${Colors.ACCENT1000};
  height: 0.25rem;
  width: ${isMobile ? '0.5rem' : isTablet ? '2.5rem' : '4.5rem'};
  margin-top: ${isMobile ? '1.2rem' : isTablet ? '1.5rem' : '2.25rem'};
  margin-right: ${isMobile ? '1rem' : isTablet ? '1.5rem' : '2.25rem'};
`;

const contactUsContainerStyle = (isMobile: boolean) => css`
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0.25rem 1.25rem 1.25rem;
  background-color: ${Colors.ACCENT1000};
  height: ${isMobile ? '2.5rem' : '3.5rem'};
  width: 15.8rem;
  border-radius: 3.5rem;
  margin-top: ${isMobile ? '1.5rem' : '2.5rem'};
`;

const contactUsTextStyle = css`
  z-index: 2;
  ${UIElementsFont}
`;

const phoneIconBackgroundStyle = (isMobile: boolean) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.PRIMARY100};
  padding: 0.75rem;
  border-radius: 3rem;
  height: ${isMobile ? '28px' : '48px'};
  width: ${isMobile ? '28px' : '48px'};
`;

const phoneImageStyle = css`
  min-width: 16px;
`;

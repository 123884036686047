import { css, cx } from '@emotion/css';
import { headingH2, headingH3, paragraph } from '../../../shared/styles/fonts';
import { Container } from '../../../shared/components/Container';
import { Colors } from '../../../shared/styles/colors';
import React from 'react';
import { DeviceSizes } from '../../../shared/utils/device-sizes';
import { useMediaQuery } from 'react-responsive';

export const ContactSection = () => {
  return (
    <section id={'kontakt'} className={containerStyle}>
      <Container>
        <div className={columnsStyle}>
          <div>
            <h2 className={headingStyle}>Sveti Antun</h2>
            <h3 className={subHeadingStyle}>Dom za starije i nemoćne</h3>
            <p className={contactInfoStyle}>Galovići 30</p>
            <p className={contactInfoStyle}>1000, Zagreb</p>
            <p className={contactInfoStyle}>Hrvatska</p>
            <div className={contactInfoContainerStyle}>
              <p className={cx(contactInfoStyle, emphasisedText)}>info.domsvetiantun@gmail.com</p>
              <p className={cx(contactInfoStyle, emphasisedText)}>+385 98 1700 971</p>
            </div>
          </div>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                width="100%"
                frameBorder="0"
                scrolling="no"
                src="https://maps.google.com/maps?width=820&amp;height=642&amp;hl=en&amp;q=Galovići 30&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const containerStyle = css`
  min-height: 47.75rem;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const headingStyle = css`
  text-align: left;
  ${headingH2};
  color: ${Colors.ACCENT1000};
  margin-bottom: 0.5rem;
`;

const subHeadingStyle = css`
  margin-bottom: 1rem;
  ${headingH3};
  text-align: left;
  color: ${Colors.ACCENT1000};
`;

const columnsStyle = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    grid-template-columns: auto;
  }
`;

const contactInfoContainerStyle = css`
  margin-top: 1rem;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    margin-bottom: 4rem;
  }
`;

const contactInfoStyle = css`
  ${paragraph};
  font-weight: 400;
  text-align: left;
`;

const emphasisedText = css`
  font-weight: bold;
  text-decoration: underline;
`;

import { LandingSection } from './sections/LandingSection';
import { FirstQuoteSection } from './sections/FirstQuoteSection';
import { CoreValuesSection } from './sections/CoreValuesSection';
import { WhySection } from './sections/WhySection';
import { SecondQuoteSection } from './sections/SecondQuoteSection';
import { UserNeedsSection } from './sections/UserNeedsSection';
import { PersonnelSection } from './sections/PersonnelSection';
import { GallerySection } from './sections/GallerySection';
import { ContactSection } from './sections/ContactSection';

export const HomePage = () => {
  return (
    <>
      <LandingSection />
      <FirstQuoteSection />
      <CoreValuesSection />
      <WhySection />
      <SecondQuoteSection />
      <UserNeedsSection />
      <PersonnelSection />
      <GallerySection />
      <ContactSection />
    </>
  );
};

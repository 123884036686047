import React, { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DeviceSizes } from '../utils/device-sizes';
import { Container } from './Container';
import { css } from '@emotion/css';
import { activeNavigationElementFont, inactiveNavigationElementFont } from '../styles/fonts';
import Hamburger from 'hamburger-react';
import slugify from 'slugify';
//@ts-ignore
import { scroller } from 'react-scroll';
//@ts-ignore
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Colors } from '../styles/colors';

interface NavigationBarProps {
  selectedElement: string;
  setSelectedElement(element: string): void;
  elements: string[];
}

const MobileNavigationMenu = (props: NavigationBarProps) => {
  const { elements, selectedElement, setSelectedElement } = props;
  return (
    <div className={mobileNavigationMenu}>
      <div className={mobileNavigationMenuContent}>
        <Container>
          <div className={mobileNavigationElements}>
            {elements.map((element) => {
              const slug = slugify(element.toLowerCase());
              return (
                <a
                  key={element}
                  href={slug}
                  className={mobileNavigationElementFont}
                  onClick={(event) => {
                    event.preventDefault();
                    setSelectedElement(element);
                    scroller.scrollTo(slug, { offset: -40 });
                  }}>
                  {element}
                </a>
              );
            })}
          </div>
        </Container>
      </div>
    </div>
  );
};

const MobileNavigationBar = (props: NavigationBarProps) => {
  const { elements, selectedElement, setSelectedElement } = props;

  const [toggled, setToggled] = useState<boolean>(false);
  const targetElement = document.querySelector('body');
  return (
    <>
      <nav className={navigationStyle}>
        <Container>
          <div className={navigationContentStyle}>
            <a
              href={'o-domu'}
              onClick={(event) => {
                event.preventDefault();
                setSelectedElement('O Domu');
                scroller.scrollTo('o-domu', { offset: -40 });
                enableBodyScroll(targetElement);
                setToggled(false);
              }}>
              <img src={'/assets/logo.svg'} className={logoStyle} />
            </a>
            <Hamburger
              color={Colors.PRIMARY1000}
              toggled={toggled}
              toggle={() => {
                if (toggled) {
                  setToggled(false);
                  enableBodyScroll(targetElement);
                } else {
                  setToggled(true);
                  disableBodyScroll(targetElement);
                }
              }}
            />
          </div>
        </Container>
      </nav>
      {toggled && (
        <MobileNavigationMenu
          elements={elements}
          selectedElement={selectedElement}
          setSelectedElement={(value) => {
            setSelectedElement(value);
            setToggled(false);
            enableBodyScroll(targetElement);
          }}
        />
      )}
    </>
  );
};

const DesktopNavigationBar = (props: NavigationBarProps) => {
  const { elements, selectedElement, setSelectedElement } = props;
  return (
    <nav className={navigationStyle}>
      <Container>
        <div className={navigationContentStyle}>
          <a
            href={'o-domu'}
            onClick={(event) => {
              event.preventDefault();
              setSelectedElement('O Domu');
              scroller.scrollTo('o-domu', { offset: -40 });
            }}>
            <img src={'/assets/logo.svg'} className={logoStyle} />
          </a>
          <div className={navigationElementsContainerStyle}>
            {elements.map((element) => {
              const slug = slugify(element.toLowerCase());
              return (
                <a
                  key={element}
                  href={slug}
                  className={desktopNavigationElementFont}
                  onClick={(event) => {
                    event.preventDefault();
                    setSelectedElement(element);
                    scroller.scrollTo(slug, { offset: -40 });
                  }}>
                  {element}
                </a>
              );
            })}
          </div>
        </div>
      </Container>
    </nav>
  );
};

export const NavigationBar = () => {
  const isNotDesktop = useMediaQuery({ maxWidth: DeviceSizes.TABLET });
  const [selectedElement, setSelectedElement] = useState<string>('O Domu');
  const elements = useMemo(() => ['O Domu', 'Usluge', 'Galerija', 'Kontakt'], []);

  return (
    <>
      {isNotDesktop ? (
        <MobileNavigationBar
          elements={elements}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
      ) : (
        <DesktopNavigationBar
          elements={elements}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
      )}
    </>
  );
};

const logoStyle = css`
  align-self: center;
  max-width: 145px;
  max-height: 52px;
`;

const navigationContentStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const navigationStyle = css`
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  background-color: white;
  box-sizing: border-box;
  text-transform: uppercase;
  position: fixed;
  height: 4.5rem;
  width: 100%;
  z-index: 1000;
`;

const navigationElementsContainerStyle = css`
  a {
    margin-left: 2.5rem;
  }
`;

const mobileNavigationMenu = css`
  position: fixed;
  background-color: ${Colors.PRIMARY100};
  min-height: 100vh;
  width: 100%;
  z-index: 500;
  padding-top: 4.5rem;
`;

const mobileNavigationMenuContent = css`
  position: fixed;
  z-index: 600;
  width: 100%;
`;

const mobileNavigationElements = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const mobileNavigationElementFont = css`
  ${inactiveNavigationElementFont};
  margin-top: 2.5rem;
  text-transform: uppercase;
  width: fit-content;
`;

const desktopNavigationElementFont = css`
  ${inactiveNavigationElementFont};
  &:hover {
    ${activeNavigationElementFont};
  }
`;

import { css } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import { headingH3, paragraph } from '../../../shared/styles/fonts';
import { DeviceSizes } from '../../../shared/utils/device-sizes';
import React from 'react';
import { Container } from '../../../shared/components/Container';

export const CoreValuesSection = () => {
  return (
    <section>
      <Container>
        <div className={containerStyle}>
          <div className={columnStyle}>
            <img src={'/assets/ourusers.png'} className={columnImageStyle} />
            <h3 className={columnTitleStyle}>Naši korisnici</h3>
            <p className={columnTextStyle}>
              Naši korisnici uživaju u toplini doma, lijepom orkuženju uz dobru njegu i dobru hranu.
            </p>
          </div>
          <div className={columnStyle}>
            <img src={'/assets/help.png'} className={columnImageStyle} />
            <h3 className={columnTitleStyle}>Pomoć u svakom trenu</h3>
            <p className={columnTextStyle}>
              Dom za starije i nemoćne osobe sveti Antun je ustanova socijalne skrbi koja{' '}
              <span className={emphasisedText}>
                pruža smještaj pokretnim, polupokretnim i nepokretnim osobama.
              </span>
            </p>
          </div>
          <div className={columnStyle}>
            <img src={'/assets/env.png'} className={columnImageStyle} />
            <h3 className={columnTitleStyle}>Okruženje</h3>
            <p className={columnTextStyle}>
              Dom sveti Antun smješten je u{' '}
              <span className={emphasisedText}>
                mirnom dijelu podsljemenske zone u prirodnom okruženju
              </span>{' '}
              podno Parka prirode Medvednica.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

const containerStyle = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 2.25rem;
  min-height: 32.5rem;
  background-color: ${Colors.PRIMARY100};
  padding: 7rem 0;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    grid-template-columns: auto;
  }
`;

const columnStyle = css`
  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    margin-bottom: 4.5rem;
  }
`;
const columnImageStyle = css`
  margin-bottom: 2rem;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;
const columnTitleStyle = css`
  text-align: left;
  margin-bottom: 1rem;
  ${headingH3};
  color: ${Colors.PRIMARY1000};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    text-align: center;
  }
`;
const columnTextStyle = css`
  text-align: left;
  ${paragraph};

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    text-align: center;
  }
`;

const emphasisedText = css`
  font-weight: bold;
  text-decoration: underline;
`;

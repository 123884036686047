import { css } from '@emotion/css';
import { Container } from './Container';
import { Colors } from '../styles/colors';
import { footerText } from '../styles/fonts';

export const Footer = () => {
  return (
    <section className={containerStyle}>
      <Container>
        <h4 className={textStyle}>
          © {new Date().getFullYear()} Sveti Antun. Sva prava pridržana.
        </h4>
      </Container>
    </section>
  );
};

const containerStyle = css`
  min-height: 6.5rem;
  background-color: ${Colors.PRIMARY500};
  padding: 4rem 0;
`;

const textStyle = css`
  ${footerText}
`;

import { css } from '@emotion/css';
import { desktopHeadingOverline, headingH2 } from '../../../shared/styles/fonts';
import { Container } from '../../../shared/components/Container';
import { Colors } from '../../../shared/styles/colors';
import React from 'react';
import { DeviceSizes } from '../../../shared/utils/device-sizes';

export const UserNeedsSection = () => {
  return (
    <section className={containerStyle}>
      <Container>
        <div className={columnsStyle}>
          <div>
            <h2 className={headingStyle}>Zadovoljavamo sve potrebe naših korisnika</h2>
            <h4 className={subHeadingStyle}>
              Dom je moderno uređen i opremljen u skladu sa svim standardnima socijalne skrbi.
            </h4>
            <p>
              Dom raspolaže s jednokrevetnim i dvokrevetnim sobama prilagođenim korisnicima treće
              životne dobi, a posebno pažnju posvećuje pacijentima u terminalnoj fazi života, stoga
              je dio ostanove prilagođen upravo njima i njihovim potrebama.
            </p>
          </div>
          <img src={'/assets/room.png'} className={imageStyle} />
        </div>
      </Container>
    </section>
  );
};

const containerStyle = css`
  background-color: ${Colors.PRIMARY500};
  min-height: 47.75rem;
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const headingStyle = css`
  text-align: left;
  ${headingH2};
  color: ${Colors.TEXT1000b};
  margin-bottom: 3rem;
`;

const subHeadingStyle = css`
  margin-bottom: 1rem;
  ${desktopHeadingOverline};
  text-align: left;
  color: ${Colors.ACCENT1000};
`;

const columnsStyle = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    grid-template-columns: auto;
  }
`;

const imageStyle = css`
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: ${DeviceSizes.TABLET}px) {
    margin-top: 4rem;
  }
`;
